.color-picker-input {
    width: 10rem;
    padding: 0.5313rem 0rem;
    padding-left: 1rem;
	border-width: 1px;
	border-color: #a8a8a8;
    border-radius: 0.3125rem 0  0 0.3125rem;
	font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
}
