.htmlTags h1 {
    font-size: 1.875rem;
  }
  
  .htmlTags h2 {
    font-size: 1.5rem;
  }
  
  .htmlTags h3 {
    font-size: 1.25rem; 
  }
  
  .htmlTags a {
    color: #3182ce;
    text-decoration: underline;
  }
  
  .htmlTags ol {
    list-style-type: decimal;
    margin-left: 20px;
  }

  .htmlTags ul {
    list-style-type: disc;
    margin-left: 20px;
  }