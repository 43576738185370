html, body, #root {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.App-main {
	height: 100%;
	width: 100%;
}

.button {
	border: none;
	background-color: var(--primary);
	font-size: 1.4rem;
	padding: 14px;
	border-radius: 4px;
	color: var(--text-color);
	margin-top: 30px;
	margin-bottom: 30px;
}

.button:hover {
	background-color: var(--primary-dark);
}

a {
	color: var(--primary);
}

a:hover {
	color: var(--primary-dark);
}

.react-datepicker__input-container {
	width: inherit;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__header {
	background-color: var(--chakra-colors-primary) !important;
	color: #fff !important;
}

.react-datepicker__current-month {
	color: #fff !important;
}

.react-datepicker__day-names > .react-datepicker__day-name {
	color: #fff !important;
}

.react-datepicker-year-header {
	background-color: var(--chakra-colors-primary) !important;
	color: #fff !important;
	padding: 0.8em !important;
}

.react-datepicker__month {
	padding: 1.2em;
}

.react-datepicker__month-text {
	padding: 5px;
	font-size: 1.2em;
}

.react-datepicker__month-text--keyboard-selected {
	background-color: var(--chakra-colors-primary) !important;
}

.react-datepicker__navigation {
	margin-top: 0.5em;
}

.react-datepicker__navigation:focus {
	outline: none;
}

/* rc steps */

.rc-steps-item-content .rc-steps-item-title,
.rc-steps-item-description {
	color: #22365f !important;
}

.rc-steps-item-description {
	text-align: center !important;
	font-weight: 500 !important;
	font-size: 12px !important;
}

/* finish rc steps */

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}
}

@page {
	size: auto;
	margin: 20mm;
}
